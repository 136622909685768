<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      accept=".csv"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p v-if="importCount > 0">{{ importCount }}件の取り込みが完了しました。</p>
              <p v-if="errorRows.length > 0">FT在庫突合の取り込みでエラーが発生しました。確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete(false)">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="FT在庫一括取込"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import ProductConditionTypes from "../../consts/FtProductConditionTypes";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "LogisticsStockImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      columnDefs: [
        { headerName: "商品コード", field: "productCd" },
        { headerName: "JANコード", field: "janCode" },
        { headerName: "商品名", field: "productName" },
        { headerName: "大分類区分", field: "majorClassType" },
        { headerName: "大分類区分名", field: "majorClassName" },
        { headerName: "中分類区分", field: "middleClassType" },
        { headerName: "中分類区分名", field: "middleClassName" },
        { headerName: "小分類区分", field: "subcategoryType" },
        { headerName: "小分類区分名", field: "subcategoryName" },
        { headerName: "商品備考", field: "productRemarks" },
        { headerName: "荷姿区分", field: "packingType" },
        { headerName: "荷姿区分名称", field: "packingName" },
        { headerName: "ロケーションコード", field: "locationCode" },
        { headerName: "在庫ステータス", field: "statusCode" },
        { headerName: "在庫ステータス名称", field: "statusName" },
        { headerName: "状態コード", field: "conditionCode" },
        { headerName: "状態コード名称", field: "conditionName" },
        { headerName: "ロット番号", field: "lotNumber" },
        { headerName: "賞味期限", field: "expirationDate" },
        { headerName: "入庫日", field: "receiptDate" },
        { headerName: "在庫数", field: "stockQuantity" },
      ],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
    },
    csvRecords: [],
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      maxByteLength: Validation.maxByteLength,
      isCsv: Validation.isCsv,
      isCode: Validation.isCode,
      maxLength: Validation.maxLength,
    },
    importCount: 0,
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    errorRows: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          if (this.getExt(this.file.name) != "csv") {
            this.$dialog.notify.error(`CSVのみアップロード可能です(${this.file.name})`);
            return;
          }
          const csvText = await FileUtils.readAsText(this.file, true);
          const csvLines = csvText.split("\n").map((line) => line.split(",").map((filed) => filed.trim()));
          this.csvRecords = [];
          csvLines.forEach((line, index) => {
            if (index !== 0 && line.length > 1) {
              this.csvRecords.push({
                rowNumber: index,
                productCd: line[0],
                janCode: line[1],
                productName: line[2],
                majorClassType: line[3],
                majorClassName: line[4],
                middleClassType: line[5],
                middleClassName: line[6],
                subcategoryType: line[7],
                subcategoryName: line[8],
                productRemarks: line[9],
                packingType: line[10],
                packingName: line[11],
                locationCode: line[12],
                statusCode: line[13],
                statusName: line[14],
                conditionCode: line[15],
                conditionName: line[16],
                lotNumber: line[17],
                expirationDate: line[18],
                receiptDate: line[19],
                stockQuantity: line[20],
              });
            }
          });
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    async onSubmit() {
      try {
        this.loadingOn();

        var requestRecords = [];
        this.errorRows = [];
        let isValid = true;
        let csvRecordCnt = 0;
        this.csvRecords.forEach((record) => {
          csvRecordCnt++;
          var error = this.validateRow(record);
          if (error != true) {
            this.errorRows.push({
              rowNumber: record.rowNumber,
              errorMessage: error,
            });
            isValid = false;
          } else {
            requestRecords.push(this.requestFormat(record));
          }
        });
        if (csvRecordCnt == 0) {
          isValid = false;
        }
        if (isValid) {
          try {
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("C040802", requestRecords.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            this.errorRows = [];
            this.loadingOn();
            // インポートAPIコール
            const response = await this.$store.dispatch("stockMatch/import", {
              rows: requestRecords,
              splitNum: 10000,
            });
            // 処理結果判定
            let error = response.data?.header;
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
                this.importCount = response.data.contents?.count;
                this.step++;
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
                this.importCount = response.data.contents?.count;
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    this.errorRows.push({
                      rowNumber: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // エラー一覧表示処理
                if (this.errorRows.length > 0) {
                  this.$refs.importErrorGrid.open({ records: this.errorRows });
                } else {
                  this.$refs.importErrorGrid.close();
                }
                // 画面は完了画面に進める
                this.step++;
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("LogisticsStockImport::onSubmit", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        } else {
          this.$refs.importErrorGrid.open({ records: this.errorRows });
          this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
            timeout: 2300,
          });
        }
      } catch (error) {
        console.error("LogisticsStockImport::onSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      //商品コード (商品コードはDelphoi後はjancodeと一致なので、チェック不要)
      // this.setValidMessage(this.rules.maxLength(12)(row.productCd), "商品コード", messages);
      // JANCODE
      this.setValidMessage(this.rules.required(row.janCode), "JANCODE", messages);
      this.setValidMessage(this.rules.isCode(row.janCode), "JANCODE", messages);
      this.setValidMessage(this.rules.maxLength(13)(row.janCode), "JANコード", messages);
      // 状態コード
      this.setValidMessage(this.rules.required(row.conditionCode), "状態コード", messages);
      this.setValidMessage(this.rules.isCode(row.conditionCode), "状態コード", messages);
      this.setValidMessage(this.isProductConditionTypes(row.conditionCode), "状態コード", messages);
      // 在庫数
      this.setValidMessage(this.rules.required(row.stockQuantity), "在庫数", messages);
      this.setValidMessage(this.rules.isNumber(row.stockQuantity), "在庫数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.stockQuantity), "在庫数", messages);
      // 商品名
      this.setValidMessage(this.rules.maxLength(100)(row.productName), "商品名", messages);
      // 大分類区分
      this.setValidMessage(this.rules.isCode(row.majorClassType), "大分類区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.majorClassType), "大分類区分", messages);
      // 大分類区分名
      this.setValidMessage(this.rules.maxLength(100)(row.majorClassName), "大分類区分名", messages);
      // 中分類区分
      this.setValidMessage(this.rules.isCode(row.middleClassType), "中分類区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.middleClassType), "中分類区分", messages);
      //中分類区分名
      this.setValidMessage(this.rules.maxLength(100)(row.middleClassName), "中分類区分名", messages);
      // 小分類区分
      this.setValidMessage(this.rules.isCode(row.subcategoryType), "小分類区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.subcategoryType), "小分類区分", messages);
      //小分類区分名
      this.setValidMessage(this.rules.maxLength(100)(row.subcategoryName), "小分類区分名", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.productRemarks), "商品備考", messages);
      // 荷姿区分
      this.setValidMessage(this.rules.isCode(row.packingType), "荷姿区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.packingType), "荷姿区分", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.packingName), "荷姿区分名称", messages);
      // ロケーションコード
      this.setValidMessage(this.rules.isCode(row.locationCode), "ロケーションコード", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.locationCode), "ロケーションコード", messages);
      // 在庫ステータス
      this.setValidMessage(this.rules.isCode(row.statusCode), "在庫ステータス", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.statusCode), "在庫ステータス", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.statusName), "在庫ステータス名称", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.conditionName), "状態コード名称", messages);
      // ロット番号
      this.setValidMessage(this.rules.isCode(row.lotNumber), "ロット番号", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.lotNumber), "ロット番号", messages);
      // 賞味期限
      this.setValidMessage(this.isDate(row.expirationDate), "賞味期限", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.expirationDate), "賞味期限", messages);
      this.setValidMessage(this.rules.maxLength(100)(row.receiptDate), "入庫日", messages);
      // 入庫日
      this.setValidMessage(this.isDate(row.receiptDate), "入庫日", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        janCode: this.stringFormat(row.janCode),
        productName: this.stringFormat(row.productName),
        majorClassType: this.stringFormat(row.majorClassType),
        majorClassName: this.stringFormat(row.majorClassName),
        middleClassType: this.stringFormat(row.middleClassType),
        middleClassName: this.stringFormat(row.middleClassName),
        subcategoryType: this.stringFormat(row.subcategoryType),
        subcategoryName: this.stringFormat(row.subcategoryName),
        productRemarks: this.stringFormat(row.productRemarks),
        packingType: this.stringFormat(row.packingType),
        packingName: this.stringFormat(row.packingName),
        locationCode: this.stringFormat(row.locationCode),
        statusCode: this.stringFormat(row.statusCode),
        statusName: this.stringFormat(row.statusName),
        conditionCode: this.numberFormat(row.conditionCode),
        conditionName: this.stringFormat(row.conditionName),
        lotNumber: this.stringFormat(row.lotNumber),
        expirationDate: this.stringDateFormat(row.expirationDate),
        receiptDate: this.stringFormat(row.receiptDate),
        stockQuantity: this.numberFormat(row.stockQuantity),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(value, "YYYY/MM/DD").format("YYYY-MM-DD");
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isProductConditionTypes(value) {
      if (value == null || value == "") return true;
      if (!ProductConditionTypes.of(value)) return "10,20の形式で入力してください";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    getExt(filename) {
      const pos = filename.lastIndexOf(".");
      if (pos === -1) return "";
      return filename.slice(pos + 1);
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
