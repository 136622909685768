<template>
  <div class="dp-ag-grid-selection-filter">
    <select class="dp-ag-grid-selection-select" v-model="choice" @change="updateFilter">
      <option value="none">{{ noneString }}</option>
      <option value="true">{{ trueString }}</option>
      <option value="false">{{ falseString }}</option>
    </select>
  </div>
</template>

<style scoped>
.dp-ag-grid-selection-filter {
  padding: 12px 12px 6px 12px;
}
.dp-ag-grid-selection-select {
  border: solid 1px #bbb;
  border-radius: 5px;
  height: 30px;
  min-width: 135px;
  outline: none;
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;
}
.dp-ag-grid-selection-filter:focus {
  box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%);
}
</style>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";

export default VueJS.extend({
  data: function () {
    return {
      choice: "none",
    };
  },
  computed: {
    noneString() {
      return this.params?.caption?.none == null ? "" : this.params.caption.none;
    },
    trueString() {
      return this.params?.caption?.true == null ? "はい" : this.params.caption.true;
    },
    falseString() {
      return this.params?.caption?.false == null ? "いいえ" : this.params.caption.false;
    },
  },
  methods: {
    updateFilter() {
      this.params.filterChangedCallback();
    },
    isFilterActive() {
      return this.choice !== "none";
    },
    doesFilterPass(params) {
      const colId = this.params.column.colId;
      const choice = this.choice === "true";
      return params.data[colId] === choice;
    },
    getModel() {
      return { value: this.choice };
    },
    setModel(model) {
      this.choice = model ? model.value : "none";
    },
  },
});
</script>
