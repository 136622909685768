<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-compare</v-icon>FT在庫突合</v-app-bar-title>
          <v-spacer></v-spacer>
          <tooltip-icon-button icon="mdi-database-import" @click="onImportClick" v-if="allowedAction('C040802')"
            >FT在庫入力</tooltip-icon-button
          >
          <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
            <logistics-stock-import
              v-if="importDialog.show"
              @complete="onImportComplete"
              @expand="importDialog.width = $event ? '100%' : '800px'"
            ></logistics-stock-import>
          </v-dialog>
          <tooltip-icon-button icon="mdi-download" @click="onExportClick" v-if="allowedAction('C040803')"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :cols="12" :style="gridStyle">
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="stockRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
        ></ag-grid-vue>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import LogisticsStockImport from "../../components/stock/LogisticsStockImport.vue";
import ProductConditionTypes from "../../consts/FtProductConditionTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import { typeFormat } from "../../models/ag-grid/helpers";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { IncludeFilter } from "../../models/ag-grid/columnTypes";

export default {
  name: "StockMatching",
  components: {
    AgGridVue,
    TooltipIconButton,
    LogisticsStockImport,
  },
  data: () => ({
    gridStyle: { height: "95%" },
    selectedPageSize: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
      columnDefs: [
        { headerName: "JANCODE", field: "janCode", pinned: "left" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        {
          headerName: "不良品区分",
          colId: "badType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductConditionTypes.all() },
          valueGetter: (params) => ProductConditionTypes.of(params.data.badType),
        },
        {
          headerName: "現在庫数",
          field: "stockQuantity",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CRデータ在庫",
          field: "dpStockQuantity",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CRデータ不良FT",
          field: "dpBadQuantity",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "差異",
          field: "difference",
          pinned: "right",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
          cellStyle: (params) => (params.value == 0 ? {} : { color: "red", fontWeight: "bold" }),
          valueFormatter: NumberValueFormatter(),
        },
      ],
      rowSelection: false,
      suppressCellSelection: true,
      pagination: true,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      localeText: AG_GRID_LOCALE_JA,
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
    },
    stockRecords: [],
    selectionRows: [],
    importDialog: { show: false, width: "800px" },
  }),
  watch: {
    selectedPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  mounted() {
    this.onSearchClick();
    this.selectedPageSize = this.globalPageSize;
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.gridOptions.columnApi = params.columnApi;
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("stockMatch/search");
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "FT在庫突合一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.stockMatching).length === 0) {
          this.$dialog.warning({
            title: "FT在庫突合一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.stockMatching);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("LogisticsStockMatcnhingSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onImportClick() {
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      if (!canceled) {
        this.onSearchClick();
      }
      console.log("StockMatching::onImportComplete", "canceled", canceled);
    },
    onExportClick() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        if (!column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        fileName: `FT在庫突合`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
  },
};
</script>
