<template>
  <div class="dp-ag-grid-selection-filter">
    <select class="dp-ag-grid-selection-select dp-ag-grid-input-pad" v-model="correlation" @change="updateFilter">
      <option v-for="cor in corres" :key="cor.value" :value="cor.value">{{ cor.name }}</option></select
    ><br />
    <select class="dp-ag-grid-selection-select" v-model="choice" @change="updateFilter">
      <option value=""></option>
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}</option>
    </select>
  </div>
</template>

<style scoped>
.dp-ag-grid-selection-filter {
  padding: 12px 12px 6px 12px;
}
.dp-ag-grid-selection-select {
  border: solid 1px #bbb;
  border-radius: 5px;
  height: 30px;
  min-width: 135px;
  outline: none;
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;
}
.dp-ag-grid-selection-filter:focus {
  box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%);
}
.dp-ag-grid-input-pad {
  margin: 0px 0px 6px 0px;
}
</style>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";
import VueStore from "../../../store/index";
export default VueJS.extend({
  data: function () {
    return {
      choice: "",
      options: [],
      corres: [
        { name: "一致", value: 1 },
        { name: "除外", value: 2 },
      ],
      correlation: 1,
      corporateType: "",
    };
  },
  mounted: function () {
    const nameKey = this.params.nameKey || "text";
    const valueKey = this.params.valueKey || "value";
    const options = [];
    this.params.options.forEach((option) => {
      if (typeof option === "object") {
        options.push({ name: option[nameKey], value: option[valueKey] });
      } else {
        options.push({ name: option, value: option });
      }
    });
    this.options = options;
    this.corporateType = this.params.corporateType;
  },
  methods: {
    updateFilter() {
      this.params.filterChangedCallback();
    },
    isFilterActive() {
      return this.choice !== "";
    },
    doesFilterPass(params) {
      const colId = this.params.column.colId;
      let target = null;
      // チェックボックス項目
      if (colId === "checkboxSelectionCol") {
        target = VueStore.getters["product/surfaceStatus"](params, this.corporateType);
      }
      // 画像項目
      if (colId === "imageCell") {
        if (params.data["images"]) {
          target = 1;
        } else {
          target = 2;
        }
      }
      if (this.correlation == 1) {
        return target === this.choice;
      } else if (this.correlation == 2) {
        return target !== this.choice;
      }
      return true;
    },
    getModel() {
      return { value: this.choice };
    },
    setModel(model) {
      this.choice = model ? model.value : "";
    },
  },
});
</script>
